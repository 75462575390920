<template>
  <div>
    <Form :data="role" :isSubmitting="isSubmitting" @submit="update" />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api/dist/vue-composition-api'
import Form from '../shared/Form'
import useRoleEdit from './useRoleEdit'

export default {
  components: {
    Form,
  },
  setup() {
    const { update, show, role, isSubmitting } = useRoleEdit()

    onMounted(show)

    return {
      update,
      role,
      isSubmitting,
    }
  },
}
</script>
